export default {
  aDay: 'a day',
  addReply: 'Add a reply',
  addYourMessageHere: 'Add your message here...',
  aFewHours: 'a few hours',
  aFewMinutes: 'a few minutes',
  answer: 'Answers',
  ask: 'Ask',
  attachAFile: 'Attach a file',
  attachmentErrorText:
    'There was a problem uploading your attachment. Please try again in a moment.',
  attachmentSizeErrorText: 'Attachments may be no larger than 10MB',
  beaconButtonClose: 'Close',
  beaconButtonChatMinimize: 'Minimize chat',
  beaconButtonChatOpen: 'Open chat',
  cantFindAnswer: 'Can’t find an answer?',
  chatAvailabilityChangeMessage:
    'Our team’s availability has changed and there’s no longer anyone available to chat. Send us a message instead and we’ll get back to you.',
  chatbotAgentDisconnectedMessage:
    'Agent has disconnected from the chat. It’s possible they lost their internet connection, so I’m looking for someone else to take over. Sorry for the delay!',
  chatbotConfirmationMessage:
    'Thanks! Someone from our team will jump into the chat soon.',
  chatbotGenericErrorMessage:
    'Something went wrong sending your message, please try again in a few minutes.',
  chatbotGreet:
    'Hi there! You can begin by asking your question below. Someone will be with you shortly.',
  chatbotInactivityPrompt:
    'Since the chat has gone idle, I’ll end this chat in a few minutes.',
  chatbotInvalidEmailMessage:
    'Looks like you’ve entered an invalid email address. Want to try again?',
  chatbotName: 'Help Bot',
  chatbotPromptEmail:
    'Got it. Real quick, what’s your email address? We’ll use it for any follow-up messages.',
  chatButtonDescription: 'Talk to our team in real-time',
  chatButtonLabel: 'Chat',
  chatConnected: 'Connected to Agent',
  chatEndCalloutHeading: 'All done!',
  chatEndCalloutLink: 'Return home',
  chatEndCalloutMessage:
    'A copy of this conversation will land in your inbox shortly.',
  chatEnded: 'Agent ended the chat',
  chatEndUnassignedCalloutHeading: 'Sorry about that',
  chatEndUnassignedCalloutMessage:
    'It looks like nobody made it to your chat. We’ll send you an email response as soon as possible.',
  chatEndWaitingCustomerHeading: 'Sorry about that',
  chatEndWaitingCustomerMessage:
    'Your question has been added to our email queue and we’ll get back to you shortly.',
  chatHeadingSublabel: 'We’ll be with you soon',
  chatHeadingTitle: 'Chat with our team',
  continueEditing: 'Continue writing…',
  customFieldsValidationLabel: 'Please complete all fields',
  defaultMessageErrorText:
    'There was a problem sending your message. Please try again in a moment.',
  docsArticleErrorText:
    'There was a problem retrieving this article. Please double-check your internet connection and try again.',
  docsSearchEmptyText: 'We couldn’t find any articles that match your search.',
  docsSearchErrorText:
    'There was a problem retrieving articles. Please double-check your internet connection and try again.',
  emailContinueConversation:
    'If you’ve got any other questions, feel free to hit reply and continue the conversation.',
  emailCopyOfDiscussion: 'Here’s a copy of your discussion',
  emailEndedLineItem: 'Agent ended the chat',
  emailGreeting: 'Hey Customer',
  emailHeading: 'Today’s chat with Agent',
  emailJoinedLineItem: 'Agent joined the chat',
  emailLabel: 'Email address',
  emailValidationLabel: 'Please use a valid email address',
  emailYou: 'You',
  endChat: 'End chat',
  ending: 'Ending...',
  escalationQuestionFeedback: 'Did this answer your question?',
  escalationQuestionFeedbackNo: 'No',
  escalationQuestionFeedbackYes: 'Yes',
  escalationSearchText: 'Browse our help docs for an answer to your question',
  escalationSearchTitle: 'Keep searching',
  escalationTalkText: 'Talk with a friendly member of our support team',
  escalationTalkTitle: 'Talk to us',
  escalationThanksFeedback: 'Thanks for the feedback',
  escalationWhatNext: 'What next?',
  firstAFewQuestions: 'Let’s begin with a few questions',
  getInTouch: 'Get in touch',
  history: 'History',
  howCanWeHelp: 'How can we help?',
  justNow: 'Just Now',
  lastUpdated: 'Last updated',
  mayNotBeEmpty: 'May not be empty',
  messageButtonLabel: 'Message',
  messageConfirmationText: 'You’ll receive an email reply within a few hours.',
  messageLabel: 'How can we help?',
  messageSubmitLabel: 'Send a message',
  nameLabel: 'Name',
  next: 'Next',
  nothingFound: 'Hmm…',
  previousMessageErrorText:
    'There was a problem retrieving this message. Please double-check your Internet connection and try again.',
  previousMessages: 'Previous messages',
  received: 'Received',
  relatedArticles: 'Related Articles',
  responseTime: 'We usually respond in a few hours',
  searchLabel: 'What can we help you with?',
  sendAMessage: 'Send a message',
  sendMessage: 'Send message',
  subjectLabel: 'Subject',
  suggestedForYou: 'Suggested For You',
  tryAgain: 'Try again',
  tryBroaderTerm: 'Try searching a broader term, or',
  uploadAnImage: 'Upload an image',
  viewAndUpdateMessage: 'You can view and update your message in',
  waitingForAnAnswer: 'Waiting for an answer',
  weAreOnIt: 'We’re on it!',
  weUsuallyRespondIn: 'We usually respond in',
  you: 'You',
}
